<template>
  <div>
    <collapse-card
      :model-value="true"
      :show-collapse-button="false"
      disable-collapse
      class="w-full"
    >
      <template #title>
        <span class="mr-2 text-sm font-medium">
          Resend Notification
        </span>
      </template>
      <template #cardContent>
        <div class="py-2 px-4">
          <base-input
            v-model="ResendNotificationFormData.submissionId"
            title="Provide Submission ID"
            type="text"
            container-class="flex items-center mb-4"
            label-class="label-w-46"
            label="Submission Id"
            is-required
          />
          <div class="flex justify-center">
            <base-button
              variant="btn-primary"
              :disabled="!isBtnActive || isRunning"
              class="mr-4"
              text="Resend Notification"
              @click="handleResendNotification()"
            />
            <base-button
              variant="btn-link"
              text="Reset"
              @click="handleFormReset()"
            />
          </div>
        </div>
      </template>
    </collapse-card>
  </div>
</template>

<script>
import { defineAsyncComponent, reactive, computed, ref } from 'vue';
import { useStore } from 'vuex';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, SUCCESS } from '@/constants/alerts';

export default {
    name: 'ResendNotification',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    setup () {
        const store = useStore();
        const { notificationsStack } = useToastNotifications();
        const ResendNotificationFormData = reactive({
            submissionId: ''
        });
        const isBtnActive = computed(() => ResendNotificationFormData.submissionId.length > 0);
        const isRunning = ref(false);
        const handleResendNotification = async () => {
            try {
                isRunning.value = true
                await store.dispatch('baSubmissions/resendNotification', {
                    bodyPayload: {
                        submissionId: ResendNotificationFormData.submissionId,
                    }
                });
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: 'Send Notification successfully.'
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `Not able to send the notification for ${ResendNotificationFormData.submissionId}.`
                });
            } finally {
              isRunning.value = false
            }
        };
        const handleFormReset = () => {
            ResendNotificationFormData.submissionId = '';
        };

        return {
            ResendNotificationFormData,
            isBtnActive,
            isRunning,
            handleResendNotification,
            handleFormReset
        };
    }
};
</script>
